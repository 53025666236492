/* global localStorage */
import { h, render } from 'preact'; /** @jsx h */
import { useState, useEffect } from 'preact/hooks';

function getItem(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        return null;
    }
}
function setItem(key, obj) {
    try {
        if (typeof obj === 'string') {
            return localStorage.setItem(key, obj);
        }

        return localStorage.setItem(key, JSON.stringify(obj));
    } catch (e) {
        return null;
    }
}
/*
function remove(key) {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
}
*/

const COOKIE_CONSENT_KEY = 'snla-gtm-cookie-consent';
const GTM_KEY = 'GTM-KKMPMN2';

const CookieConsent = () => {
    const savedSetting = getItem(COOKIE_CONSENT_KEY);

    const [show, setShow] = useState(savedSetting === null);
    const [loadGtm, setLoadGtm] = useState(savedSetting === 'yes');

    useEffect(() => {
        if (loadGtm) {
            // laster inn MTM
            const mtmScript = document.createElement('script');
            mtmScript.innerHTML = `var _mtm = window._mtm = window._mtm || [];
                                _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                                g.async=true; g.src='https://analytics.nlaas.no/js/container_JaxAH9aa.js'; s.parentNode.insertBefore(g,s);`;

            document.getElementsByTagName('head')[0].appendChild(mtmScript);

            // last inn GTM
            const gtmScript = document.createElement('script');
            gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_KEY}');`;

            document.getElementsByTagName('head')[0].appendChild(gtmScript);

            const gtmIframe = document.createElement('noscript');
            gtmIframe.innerHTML = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_KEY}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;

            document.body.appendChild(gtmIframe);
        }
    }, [loadGtm]);

    const acceptClick = () => {
        setItem(COOKIE_CONSENT_KEY, 'yes');

        setShow(false);
        setLoadGtm(true);
    };

    const rejectClick = () => {
        setItem(COOKIE_CONSENT_KEY, 'no');

        setShow(false);
        setLoadGtm(false);
    };

    if (!show) {
        return;
    }

    return (
        <div id="cookie-consent" className={show ? '' : 'cookie-hide'}>
            <p className="cookie-consent-text">
                Vi bruker informasjonskapsler til funksjonalitet, webanalyse og markedsføring. Les
                mer på <a href="/personvernerklaering/">personvernsiden vår</a>.
            </p>
            <div className="cookie-consent-btns">
                <button className="cookie-consent-yes" onClick={acceptClick}>
                    Godta
                </button>
                <button className="cookie-consent-no" onClick={rejectClick}>
                    Avslå
                </button>
            </div>
        </div>
    );
};

render(<CookieConsent />, document.body);
